<template>
    <transition name="fade">
        <div v-if="show" class="side-panel">
            <div class="side-panel__content">
                <button v-if="showCloseButton"
                        data-cy="panel-join-close"
                        class="side-panel__close"
                        @click="close"
                        @mousedown="closeDown"
                        @mouseup="closeUp"
                        @touchstart="closeDown"
                        @touchend="closeUp">
                    <svg width="24" height="24" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 1L13 13"
                            stroke="black"
                            stroke-width="1.5"
                            stroke-linecap="round"
                        />
                        <path
                            d="M13 1L1 13"
                            stroke="black"
                            stroke-width="1.5"
                            stroke-linecap="round"
                        />
                    </svg>
                </button>
                <section v-if="panelCookie === true" class="u-m-t2 u-m-t5@m u-m-t2@s c-cookies" id="cookies-panel">
                    <div>
                        <div class="u-align-center">
                            <img src="/img/logo-dyadey-main.svg" class="u-m-b3 u-m-b1@s" alt="Dyadey" width="171" height="33">
                            <h2 class="u-m-b3 u-text-intro@s u-m-b1@s">Consent to cookies and tracking</h2>
                            <p class="st u-m-b4 u-text-small@s u-m-b2@s">Please read about how cookies are used and how they can be managed on our website.</p>
                        </div>
                        <table>
                            <tr>
                                <td>
                                    <label class="radio">
                                        <input type="checkbox" v-model="cookiesNecessary" value="1" disabled>
                                        <span>Necessary cookies</span>
                                    </label>
                                    <p>We use necessary cookies to make our site work.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label class="radio">
                                        <input type="checkbox" v-model="cookiesAnalytics" value="1">
                                        <span>Analytics tracking</span>
                                    </label>
                                    <p>We also use analytics tracking to measure your use of the site, to help us make improvements.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label class="radio">
                                        <input type="checkbox" v-model="cookiesMarketing" value="1">
                                        <span>Marketing cookies</span>
                                    </label>
                                    <p>We also use marketing cookies to to help us improve the relevancy of advertising campaigns you receive.</p>
                                </td>
                            </tr>
                        </table>
                        <p class="u-m-b3">
                            <a @click.prevent="cookiesRejectAll" class="c-btn--profile" href="#">Reject All</a>
                            <a @click.prevent="cookiesAcceptAll" class="c-btn--profile" href="#">Accept All</a>
                        </p>
                        <p class="st u-m-b3">By pressing the Save and Close button, you consent to the use of the enabled categories of cookies. You can change your preferences and find further information in our Cookie Policy.</p>
                        <p>
                            <a @click.prevent="cookiesSave" class="c-btn u-width-full u-m-b2" href="#" data-cy="cookies-save-close">Save &amp; Close</a>
                        </p>
                    </div>
                    <div class="u-align-center u-m-t2 u-p-b5">
                        <a href="/cookies">
                            <img src="/img/icons/icon-more-information.svg" width="20" height="20">
                            <p class="u-text-meta u-color-grey2 u-p-t1"><a href="/cookies" class="u-bold">Cookie policy</a></p>
                        </a>
                    </div>
                </section>
                <section v-else-if="panelJoin" class="u-m-t8 u-m-t5@m u-m-t2@s">
                    <div>
                        <div class="u-align-center">
                            <img src="/img/logo-dyadey-main.svg" class="u-m-b3 u-m-b1@s" alt="Dyadey" width="171" height="33">
                            <h4 class="u-m-b3 u-text-intro@s u-m-b1@s">Join the {{communityName}} Conversation</h4>
                            <p class="u-m-b4 u-text-small@s u-m-b2@s">Signing in to Dyadey will allow you to immerse yourself in the content you love and have a voice in the communities you are passionate about.</p>
                        </div>
                        <a v-if="lastLogin == null" @click.prevent="showPanel('signup')" class="c-btn u-width-full u-m-b2" href="#" data-cy="sign-up-new">Sign up</a>
                        <a v-else @click.prevent="showPanel('login')" class="c-btn c-btn--blue u-width-full u-m-b2" href="#" data-cy="login-again">Login</a>
                        <div class="side-panel__split">
                            <p>OR</p>
                        </div>
                        <a v-if="lastLogin == null" @click.prevent="showPanel('login')" class="c-btn c-btn--blue u-width-full" href="#" data-cy="login-new">Login</a>
                        <a v-else @click.prevent="showPanel('signup')" class="c-btn u-width-full" href="#" data-cy="sign-up-again">Sign up</a>
                    </div>
                    <div class="u-align-center u-m-t2 u-p-b5">
                        <a href="/contact">
                            <img src="/img/icons/icon-more-information.svg" width="20" height="20">
                            <p class="u-text-meta u-color-grey2 u-p-t1">Support</p>
                        </a>
                    </div>
                </section>
                <section v-else-if="panelSignup" class="u-m-t8 u-m-t5@m u-m-t2@s">
                    <div class="u-align-center">
                        <img src="/img/logo-dyadey-main.svg" class="u-m-b3 u-m-b1@s" alt="Dyadey" width="171" height="33">
                        <h4 class="u-m-b3 u-text-intro@s u-m-b1@s">Join the Conversation</h4>
                        <p class="u-m-b2 u-text-small@s u-m-b2@s">Create an account to become a member of the communities you are passionate about.<br><a href="#" @click.prevent="showPanel('login')" class="u-underline-text">Already registered?</a></p>
                    </div>
                    <div class="u-c-red" v-if="registration && typeof registration.success != 'undefined' && registration.success === false">
                        <h5 class="u-align-center u-m-b2 u-text-small@s u-m-b2@s" data-cy="error-registering">Error Registering</h5>
                        <p class="u-m-b4 u-text-meta u-m-b2@s">There were errors with your registration - please check the messages in red below</p>
                    </div>
                    <a class="c-btn c-btn--blue2 u-width-full u-m-b2" @click.prevent="facebookLogin">Sign in with Facebook</a>
                    <div class="side-panel__split">
                        <p>OR</p>
                    </div>
                    <form method="post" action="/register" @submit="processRecaptcha" ref="registerForm">
                        <div class="u-flex">
                            <div class="u-width-half u-m-r1 u-m-b1">
                                <label for="first_name" class="c-label">First Name</label>
                                <div class="error u-text-meta u-c-red"
                                     v-if="registration && typeof registration.errors != 'undefined' && (typeof registration.errors.first_name != 'undefined' || typeof registration.errors.surname != 'undefined')"
                                     v-html="typeof registration.errors.first_name != 'undefined' ? registration.errors.first_name[Object.keys(registration.errors.first_name)[0]] : '&nbsp;'">
                                </div>
                                <input type="text" name="first_name" id="first_name" ref="first_name" class="c-textbox u-width-full" :value="registration && typeof registration.fields != 'undefined' && typeof registration.fields.first_name != 'undefined' ? registration.fields.first_name : ''" data-cy="firstname">
                            </div>
                            <div class="u-width-half u-m-l1 u-m-b1">
                                <label for="surname" class="c-label">Surname</label>
                                <div class="error u-text-meta u-c-red"
                                     v-if="registration && typeof registration.errors != 'undefined' && (typeof registration.errors.first_name != 'undefined' || typeof registration.errors.surname != 'undefined')"
                                     v-html="typeof registration.errors.surname != 'undefined' ? registration.errors.surname[Object.keys(registration.errors.surname)[0]] : '&nbsp;'">
                                </div>
                                <input type="text" name="surname" id="surname" class="c-textbox u-width-full" :value="registration && typeof registration.fields != 'undefined' && typeof registration.fields.surname != 'undefined' ? registration.fields.surname : ''" data-cy="surname">
                            </div>
                        </div>
                        <div class="u-m-b1">
                            <label for="email_reg" class="c-label">Email</label>
                            <div class="error u-text-meta u-c-red"
                                 v-if="registration && typeof registration.errors != 'undefined' && typeof registration.errors.email != 'undefined'"
                                 v-html="registration.errors.email[Object.keys(registration.errors.email)[0]]">
                            </div>
                            <input type="text" name="email" id="email_reg" class="c-textbox u-width-full" :value="registration && typeof registration.fields != 'undefined' && typeof registration.fields.email != 'undefined' ? registration.fields.email : ''" data-cy="email">
                        </div>
                        <div class="u-m-b1">
                            <label for="username" class="c-label">Username</label>
                            <div class="error u-text-meta u-c-red"
                                 v-if="registration && typeof registration.errors != 'undefined' && typeof registration.errors.username != 'undefined'"
                                 v-html="registration.errors.username[Object.keys(registration.errors.username)[0]]">
                            </div>
                            <input type="text" name="username" id="username" class="c-textbox u-width-full" :value="registration && typeof registration.fields != 'undefined' && typeof registration.fields.username != 'undefined' ? registration.fields.username : ''" data-cy="username">
                        </div>
                        <div class="u-m-b2">
                            <label for="password_reg" class="c-label">Password</label>
                            <div class="error u-text-meta u-c-red"
                                 v-if="registration && typeof registration.errors != 'undefined' && typeof registration.errors.password != 'undefined'"
                                 v-html="registration.errors.password[Object.keys(registration.errors.password)[0]]">
                            </div>
                            <input type="password" name="password" id="password_reg" class="c-textbox u-width-full" data-cy="password">
                        </div>
                        <div class="">
                            <input type="submit" value="Sign Up" class="c-btn u-width-full" data-cy="submit">
                        </div>
                        <vue-recaptcha
                            ref="invisRecaptcha"
                            @verify="recaptchaVerifyRegistration"
                            @expired="recaptchaExpired"
                            size="invisible"
                            badge="bottomright"
                            :loadRecaptchaScript="true"
                            :sitekey="recaptchaSiteKey">
                        </vue-recaptcha>
                    </form>
                    <small class="u-p-t2 u-p-t4@m u-p-t2@s">By signing in with Facebook or entering your email you agree to our <a href="/terms" class="u-bold">Terms of use</a>,
                        <a href="/privacy" class="u-bold">Privacy policy</a> &amp; <a href="/cookies" class="u-bold">Cookie policy</a>
                    </small>
                </section>
                <section v-else-if="panelLogin" class="u-m-t8 u-m-t5@m u-m-t2@s">
                    <template v-if="invalid === false">
                        <div class="u-align-center">
                            <img src="/img/logo-dyadey-main.svg" class="u-m-b3 u-m-b1@s" alt="Dyadey" width="171" height="33">
                            <h4 class="u-m-b3 u-text-intro@s u-m-b1@s">Welcome Back!</h4>
                            <p class="u-m-b4 u-text-small@s u-m-b2@s">Sign in with your previously registered account below.  <a href="#" @click.prevent="showPanel('signup')" class="u-underline-text">Not yet registered?</a>.</p>
                        </div>
                        <template v-if="lastLogin == null || lastLogin === 'facebook'">
                            <a class="c-btn c-btn--blue2 u-width-full u-m-b2" @click.prevent="facebookLogin">Sign in with Facebook</a>
                        </template>
                        <template v-else>
                            <div class="u-c-red" v-if="registration && typeof registration.success != 'undefined' && registration.success === true">
                                <h5 class="u-align-center u-m-b2 u-text-small@s">Successfully Registered</h5>
                                <p class="u-m-b2 u-text-meta">You have successfully registered - you can now sign in below</p>
                            </div>
                            <form method="post" action="/" ref="loginForm" @submit="processRecaptcha">
                                <div class="u-m-b1">
                                    <label for="email_login" class="c-label">Email or Username</label>
                                    <input type="hidden" name="loggingin" value="1">
                                    <input type="text" name="email" id="email_login" ref="email_login" class="c-textbox u-width-full" data-cy="login-email">
                                </div>
                                <div class="u-m-b2">
                                    <label for="password_login" class="c-label">Password</label>
                                    <input type="password" name="password" id="password_login" class="c-textbox u-width-full" data-cy="login-password">
                                </div>
                                <div class="">
                                    <input type="submit" value="Login" class="c-btn u-width-full" data-cy="login-submit">
                                </div>
                                <vue-recaptcha
                                    ref="invisRecaptcha"
                                    @verify="recaptchaVerifyLogin"
                                    @expired="recaptchaExpired"
                                    size="invisible"
                                    badge="bottomright"
                                    :loadRecaptchaScript="true"
                                    :sitekey="recaptchaSiteKey">
                                </vue-recaptcha>
                            </form>
                            <p class="u-m-t1 u-m-b2"><a href="#" class="u-underline-text" @click.prevent="showPanel('forgotten')">Forgot your login details?</a></p>
                        </template>
                        <div class="side-panel__split">
                            <p>OR</p>
                        </div>
                        <template v-if="lastLogin == null || lastLogin === 'facebook'">
                            <div class="u-c-red" v-if="registration && typeof registration.success != 'undefined' && registration.success === true">
                                <h5 class="u-align-center u-m-b2 u-text-small@s">Successfully Registered</h5>
                                <p class="u-m-b2 u-text-meta">You have successfully registered - you can now sign in below</p>
                            </div>
                            <form method="post" action="/" ref="loginForm" @submit="processRecaptcha">
                                <div class="u-m-b1">
                                    <label for="email_login" class="c-label">Email or Username</label>
                                    <input type="hidden" name="loggingin" value="1">
                                    <input type="text" name="email" id="email_login" ref="email_login" class="c-textbox u-width-full" data-cy="login-email-new">
                                </div>
                                <div class="u-m-b2">
                                    <label for="password_login" class="c-label">Password</label>
                                    <input type="password" name="password" id="password_login" class="c-textbox u-width-full" data-cy="login-password-new">
                                </div>
                                <div class="">
                                    <input type="submit" value="Login" class="c-btn u-width-full" data-cy="login-submit-new">
                                </div>
                                <vue-recaptcha
                                    ref="invisRecaptcha"
                                    @verify="recaptchaVerifyLogin"
                                    @expired="recaptchaExpired"
                                    size="invisible"
                                    badge="bottomright"
                                    :loadRecaptchaScript="true"
                                    :sitekey="recaptchaSiteKey">
                                </vue-recaptcha>
                            </form>
                            <p class="u-m-t1"><a href="#" class="u-underline-text" @click.prevent="showPanel('forgotten')">Forgot your login details?</a></p>
                        </template>
                        <template v-else>
                            <a class="c-btn c-btn--blue2 u-width-full u-m-b2" @click.prevent="facebookLogin">Sign in with Facebook</a>
                        </template>
                        <small class="u-p-t2 u-p-t4@m u-p-t2@s">By signing in with Facebook or entering your email you agree to our <a href="/terms" class="u-bold">Terms of use</a>,
                            <a href="/privacy" class="u-bold">Privacy policy</a> &amp; <a href="/cookies" class="u-bold">Cookie policy</a>
                        </small>
                    </template>
                    <template v-else>
                        <div class="u-align-center">
                            <img src="/img/logo-dyadey-main.svg" class="u-m-b3 u-m-b1@s" alt="Dyadey" width="171" height="33">
                            <h4 class="u-m-b3 u-text-intro@s u-m-b1@s" v-if="invalid === true">Login Failed</h4>
                            <p class="u-m-b2 u-text-small@s u-m-b2@s u-c-red">Invalid account credentials - please try to login again.</p>
                        </div>
                        <div class="u-c-red" v-if="registration && typeof registration.success != 'undefined' && registration.success === true">
                            <h5 class="u-align-center u-m-b2 u-text-small@s">Successfully Registered</h5>
                            <p class="u-m-b2 u-text-meta">You have successfully registered - you can now sign in below</p>
                        </div>
                        <form method="post" action="/" ref="loginForm" @submit="processRecaptcha">
                            <div class="u-m-b1">
                                <label for="email_login" class="c-label">Email or Username</label>
                                <input type="hidden" name="loggingin" value="1">
                                <input type="text" name="email" id="email_login" ref="email_login" class="c-textbox u-width-full" data-cy="login-email-invalid">
                            </div>
                            <div class="u-m-b2">
                                <label for="password_login" class="c-label">Password</label>
                                <input type="password" name="password" id="password_login" class="c-textbox u-width-full" data-cy="login-password-invalid">
                            </div>
                            <div class="">
                                <input type="submit" value="Login" class="c-btn u-width-full" data-cy="login-submit-invalid">
                            </div>
                            <vue-recaptcha
                                ref="invisRecaptcha"
                                @verify="recaptchaVerifyLogin"
                                @expired="recaptchaExpired"
                                size="invisible"
                                badge="bottomright"
                                :loadRecaptchaScript="true"
                                :sitekey="recaptchaSiteKey">
                            </vue-recaptcha>
                        </form>
                        <p class="u-m-t1"><a href="#" class="u-underline-text" @click.prevent="showPanel('forgotten')">Forgot your login details?</a></p>
                        <div class="side-panel__split u-m-t2">
                            <p>OR</p>
                        </div>
                        <a class="c-btn c-btn--blue2 u-width-full u-m-b2" @click.prevent="facebookLogin">Sign in with Facebook</a>
                        <small class="u-p-t2 u-p-t4@m u-p-t2@s">By signing in with Facebook or entering your email you agree to our <a href="/terms" class="u-bold">Terms of use</a>,
                            <a href="/privacy" class="u-bold">Privacy policy</a> &amp; <a href="/cookies" class="u-bold">Cookie policy</a>
                        </small>
                    </template>

                </section>
                <section v-else-if="panelForgotten" class="u-m-t8 u-m-t5@m u-m-t2@s">
                    <div class="u-align-center">
                        <img src="/img/logo-dyadey-main.svg" class="u-m-b3 u-m-b1@s" alt="Dyadey" width="171" height="33">
                        <h4 class="u-m-b3 u-text-intro@s u-m-b1@s">Password Reset Request</h4>
                        <p class="u-m-b4 u-text-small@s u-m-b2@s">Please enter your email address below and we will email you a link to reset your password.</p>
                    </div>
                    <form method="post" action="/f">
                        <div class="u-m-b1">
                            <label for="email_forgotten" class="c-label">Email</label>
                            <input type="text" name="email" id="email_forgotten" ref="email_forgotten" class="c-textbox u-width-full">
                        </div>
                        <div class="">
                            <input type="submit" value="Remind Me" class="c-btn u-width-full">
                        </div>
                    </form>
                </section>
                <section v-else-if="panelReset" class="u-m-t8 u-m-t5@m u-m-t2@s">
                    <div class="u-align-center">
                        <img src="/img/logo-dyadey-main.svg" class="u-m-b3 u-m-b1@s" alt="Dyadey" width="171" height="33">
                        <h4 class="u-m-b3 u-text-intro@s u-m-b1@s">Password Reset</h4>
                        <p class="u-m-b4 u-text-small@s u-m-b2@s">Please enter a new password below.</p>
                    </div>
                    <form method="post" :action="$route.fullPath">
                        <div class="u-m-b1">
                            <label for="password_reset" class="c-label">Password</label>
                            <div class="error u-text-meta u-c-red"
                                 v-if="reset && typeof reset.errors != 'undefined' && typeof reset.errors.password_reset != 'undefined'"
                                 v-html="reset.errors.password_reset[Object.keys(reset.errors.password_reset)[0]]">
                            </div>
                            <input type="password" name="password_reset" id="password_reset" class="c-textbox u-width-full">
                        </div>
                        <div class="">
                            <input type="submit" value="Reset Password" class="c-btn u-width-full">
                        </div>
                    </form>
                </section>
            </div>
        </div>
    </transition>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";

export default {
    components: {
        VueRecaptcha
    },
    props: {
        showSidePanel: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            user: this.$root.user,
            communityName: null,
            cookiesNecessary: true,
            cookiesAnalytics: true,
            cookiesMarketing: true,
            lastLogin: $cookies.get('ll'),
            invalid: false,
            panelCookie: false,
            panelJoin: false,
            panelSignup: false,
            panelLogin: false,
            panelForgotten: false,
            panelReset: false,
            recaptchaSiteKey: this.$root.recaptchaSiteKey,
            recaptchaSuccess: false,
            registration: typeof this.$root.registration != 'undefined' ? this.$root.registration : false,
            reset: typeof this.$root.reset != 'undefined' ? this.$root.reset : false,
            facebookLoginUrl: this.$root.facebookLoginUrl,
            show: false,
            showCloseButton: true
        }
    },
    created() {
        this.$root.$on('bus-close-side-panel', () => {
            this.close();
        });
        this.$root.$on('bus-show-cookie-panel', () => {
            this.showPanel('cookie');
        });



        this.$root.$on('show-member-panel', (communityName) => {
            this.showPanel('join', false, communityName);
        });
        this.$root.$on('show-login-panel', (invalid) => {
            this.showPanel('login', invalid);
        });
        this.$root.$on('show-signup-panel', () => {
            this.showPanel('signup');
        });
        this.$root.$on('show-reset-panel', () => {
            this.showPanel('reset');
        });
    },
    mounted() {
        if (typeof this.$root.registration != 'undefined' && typeof this.$root.registration.success != 'undefined') {
            this.showPanel('signup');
        }
        if (typeof this.$root.showReset != 'undefined' && this.$root.showReset === true) {
            this.showPanel('reset');
        }
        this.$emit('mounted');
    },
    computed: {
        cookieSettings() {
            let settings = this.$cookies.get('cookieSettings');
            if (settings == null) {
                return null;
            }
            this.panelCookie = false;
            this.cookiesAnalytics = settings.analytics;
            this.cookiesMarketing = settings.marketing;
            this.cookiesNecessary = settings.necessary;
            return settings;
        }
    },
    methods: {
        closeDown(event) {
            document.querySelector('.side-panel__close').classList.add('click-down');
        },
        closeUp(event) {
            document.querySelector('.side-panel__close').classList.replace('click-down', 'click-up');
        },
        close(clickOutside = false) {
            // delay close by 200ms to allow close animation to complete
            this.timer = setTimeout(() => {
                this.show = false;
                this.panelJoin = false;
                this.panelCookie = false;
                this.panelSignup = false;
                this.panelLogin = false;
                this.panelForgotten = false;
                this.panelReset = false;
                // clear hash - needed to allow Join button to be clicked more than once
                window.location.hash = '';
            }, 200);
        },
        cookiesAcceptAll() {
            this.cookiesAnalytics = 1;
            this.cookiesMarketing = 1;
            this.cookiesSave();
        },
        cookiesRejectAll() {
            this.cookiesAnalytics = 0;
            this.cookiesMarketing = 0;
            this.cookiesSave();
        },
        cookiesSave() {
            let cookieSettings = {
                analytics: this.cookiesAnalytics,
                marketing: this.cookiesMarketing,
                necessary: this.cookiesNecessary
            }
            cookieSettings = JSON.stringify(cookieSettings);
            this.$cookies.config('90d', '/', '', true);
            this.$cookies.set('cookieSettings', cookieSettings);
            this.$root.showCookiesPanel = false;
            window.removeEventListener('scroll', this.showPanelOnScroll);
            this.close();
        },
        facebookLogin() {
            this.$cookies.set('llfb', window.location.href, '90d', '/', '', true, 'Strict');
            this.$cookies.set('ll', 'facebook', '90d', '/', '', true, 'Strict');
            window.location = this.facebookLoginUrl;
        },
        showPanelOnScroll() {
            let percentageScroll = (Math.max(window.scrollY, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight) / document.documentElement.offsetHeight;
            if (percentageScroll > 0.75) {
                this.panelCookie = true;
                // do not allow click outside to close Cookies panel by setting $root.showCookiesPanel to true (see App.vue)
                this.$root.showCookiesPanel = true;
                // do not allow user to close panel without click on the Save & Close button
                this.showCloseButton = false;
                // show edge around cookie panel to show site behind on mobile
                this.$root.$emit('show-cookie-panel-bleed');
                this.show = true;
            }
        },
        showPanel(panel = false, invalid = false, text = null) {
            /*if (panel !== 'cookie') {
                this.$root.$el.classList.add('member-panel-faded');
            }*/
            this.panelJoin = false;
            this.panelCookie = false;
            this.panelSignup = false;
            this.panelLogin = false;
            this.panelForgotten = false;
            this.panelReset = false;
            if (panel === false) {
                return;
            }
            console.log('panel', panel);
            switch (panel) {
                case 'join':
                    this.panelJoin = true;
                    if (text != null) {
                        this.communityName = text;
                    }
                    this.show = true;
                    break;
                case 'cookie':
                    // delay cookie pop-up until 50% of screen height has been scrolled
                    if (window.location.pathname !== '/cookies') {
                        window.addEventListener('scroll', this.showPanelOnScroll);
                    // if actual Cookies page then show panel without need for scrolling
                    } else {
                        console.log('cookie panel else');
                        this.panelCookie = true;
                        // do not allow click outside to close Cookies panel by setting $root.showCookiesPanel to true (see App.vue)
                        this.$root.showCookiesPanel = true;
                        // do not allow user to close panel without click on the Save & Close button
                        this.showCloseButton = false;
                        // show edge around cookie panel to show site behind
                        // on mobile
                        this.$root.$emit('show-cookie-panel-bleed');
                        this.show = true;
                    }
                    console.log('this.panelCookie', this.panelCookie);
                    break;
                case 'signup':
                    this.panelSignup = true;
                    this.show = true;
                    break;
                case 'login':
                    this.panelLogin = true;
                    this.invalid = invalid;
                    this.show = true;
                    break;
                case 'forgotten':
                    this.panelForgotten = true;
                    this.show = true;
                    break;
                case 'reset':
                    this.panelReset = true;
                    this.show = true;
                    break;
            }
            // give focus to first field of each form
            this.$nextTick(() => {
                if (typeof this.$refs.email_login != 'undefined') {
                    this.$refs.email_login.focus();
                } else if (typeof this.$refs.first_name != 'undefined') {
                    this.$refs.first_name.focus();
                } else if (typeof this.$refs.email_forgotten != 'undefined') {
                    this.$refs.email_forgotten.focus();
                }
            });
        },
        recaptchaVerifyRegistration(response) {
            this.$http.post('/api/v1/recaptcha', {response: response})
                .then(response => {
                    this.recaptchaSuccess = !!response.data.success;
                    // if recaptcha fails do not allow form to be submitted
                    if (this.recaptchaSuccess === false) {
                        alert('We cannot submit the form at this time as you look like a bot - please try again');
                        return;
                    }
                    // set last login page show we can jump to it afterwards
                    this.$cookies.set('lle', window.location.href, '90d', '/', '', true, 'Strict');
                    this.$refs.registerForm.submit();
                }, response => {
                    console.log('Error:');
                    console.log(response);
                });
        },
        recaptchaVerifyLogin(response) {
            this.$http.post('/api/v1/recaptcha', {response: response})
                .then(response => {
                    this.recaptchaSuccess = !!response.data.success;
                    // if recaptcha fails do not allow form to be submitted
                    if (this.recaptchaSuccess === false) {
                        alert('We cannot submit the form at this time as you look like a bot - please try again');
                        return;
                    }
                    // set last login page show we can jump to it afterwards
                    this.$cookies.set('lle', window.location.href, '90d', '/', '', true, 'Strict');
                    this.$refs.loginForm.submit();
                }, response => {
                    console.log('Error:');
                    console.log(response);
                });
        },
        recaptchaExpired() {
            console.log('Recaptcha expired');
        },
        processRecaptcha(ev) {
            ev.preventDefault();
            this.$refs.invisRecaptcha.execute();
        }
    }
}
</script>
